@font-face {
    font-family: 'DomaineSansDisplay-Bold';
    src: local('DomaineSansDisplay-Bold'),url(./fonts/DomaineSansDisplay-Bold.otf) format('opentype');
  }
  @font-face {
    font-family: 'DomaineSansDisplay-SemiBold';
    src: local('DomaineSansDisplay-SemiBold'),url(./fonts/DomaineSansDisplay-Semibold.otf) format('opentype');
  }
  @font-face {
    font-family: 'DomaineSansText-Black';
    src: local('DomaineSansText-Black'),url(./fonts/DomaineSansText-Black.otf) format('opentype');
  }
  @font-face {
    font-family: 'DomaineSansText-Light';
    src: local('DomaineSansText-Light'),url(./fonts/DomaineSansText-Light.otf) format('opentype');
  }
  @font-face {
    font-family: 'DomaineSansText-Regular';
    src: local('DomaineSansText-Regular'),url(./fonts/DomaineSansText-Regular.otf) format('opentype');
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

.App{
  overflow: auto;
}